img {
  width: 100%;
}

.landing {
  position: relative;
  /* background: url("./images/front.jpg") no-repeat; */
  background-size: cover;
  background-position: center;
  margin-top: -24px;
  margin-bottom: -50px;
}

.landing-inner {
  /* padding-top: 80px; */
}

.App {
  position: relative;
  min-height: 100vh;
}

.main {
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .main {
    margin: 0;
  }
}
